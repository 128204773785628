@charset "utf-8";
/* CSS Document */
@import "import.scss";

/*============================
animation
============================*/
.animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
  }
  .animated.infinite {
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
  }
  .animated.hinge {
	-webkit-animation-duration: 2s;
	animation-duration: 2s;
  }
  @keyframes fadeInRight {
	0% {
	  opacity: 0;
	  -webkit-transform: translate3d(30%, 0, 0);
	  -ms-transform: translate3d(30%, 0, 0);
	  transform: translate3d(30%, 0, 0)
	}
	100% {
	  opacity: 1;
	  -webkit-transform: none;
	  -ms-transform: none;
	  transform: none
	}
  }
  .fadeInRight {
	-webkit-animation-name: fadeInRight;
	animation-name: fadeInRight
  }
  @keyframes fadeInRight2 {
	0% {
	  opacity: 0;
	  -webkit-transform: translate3d(15%, 0, 0);
	  -ms-transform: translate3d(15%, 0, 0);
	  transform: translate3d(15%, 0, 0)
	}
	100% {
	  opacity: 1;
	  -webkit-transform: none;
	  -ms-transform: none;
	  transform: none
	}
  }
  .fadeInRight2 {
	-webkit-animation-name: fadeInRight2;
	animation-name: fadeInRight2
  }
  @keyframes fadeInLeft {
	0% {
	  opacity: 0;
	  -webkit-transform: translate3d(-30%, 0, 0);
	  -ms-transform: translate3d(-30%, 0, 0);
	  transform: translate3d(-30%, 0, 0)
	}
	100% {
	  opacity: 1;
	  -webkit-transform: none;
	  -ms-transform: none;
	  transform: none
	}
  }
  .fadeInLeft {
	-webkit-animation-name: fadeInLeft;
	animation-name: fadeInLeft
  }
	

/*============================
#mv
============================*/
.mv-box {
    margin-bottom: 50vw;
	transition: .5s;
	opacity: 0;
	@media screen and (min-width:769px){
		margin-bottom: 4vw;
	}
	.box-inner {
        position: relative;
        height: 180vw;
        margin: 0;
		@media screen and (min-width:769px){
			height: 58.7vw;
			padding: 0;
		}
		.bg-movie {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            z-index: -1;
			height: 100%;
			background: #d9e0eb;
			transition: .5s;
			@media screen and (min-width:769px){
				text-align: right;
				height: 72%;
			}
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(217,224,235,0.4);
				transition: .5s;
				z-index: 1;
				@media screen and (min-width:769px){
					right: 0;
					width: 75%;
					left: auto;
				}
            }
            img, video {
                max-width: initial;
				height: 100%;
				transition: .5s;
				opacity: 0;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translateY(-50%) translateX(-50%);
				@media screen and (min-width:769px){
					width: 75%;
					height: auto;
					position: initial;
					transform: none;
				}
            }
		}
		.mv-txt-wrapper {
			position: relative;
			height: 100%;
			width: 100%;
			transition: .5s;
			@media screen and (min-width:769px){
				width: 90%;
				margin-right: 0;
				margin-left: auto;
			}
		}
		.mv-txt00 {
			@include fontMincho;
			font-size: 11vw;
			color: #4d5664;
			position: absolute;
			top: 50vw;
			left: 5vw;
			line-height: 1.4;
			transition: .8s;
			@media screen and (min-width:769px){
				font-size: 4.2vw;
				top: 11vw;
				left: 0;
			}
		}
		.mv-txt01 {
            width: 70.7vw;
            position: absolute;
            top: 6vw;
            left: 0;
            box-sizing: border-box;
			font-size: 7.4vw;
			line-height: 1.47;
			padding: 5.6vw;
			padding-left: 0;
			@include fontMincho;
			transition: .5s;
			opacity: 0;
			@media screen and (min-width:769px){
				font-size: 3.1vw;
				width: 39.6vw;
				padding: 2.45vw;
				padding-left: 0;
				top: 4.5vw;
				line-height: 1.5;
			}
			span {
				padding: 0.6em 0.7em;
				line-height: 1.75;
				background: #fff;
				-webkit-box-decoration-break: clone;
				box-decoration-break: clone;
				@media screen and (min-width:769px){
					padding: 0.5em 0.8em;
					line-height: 1.7;
				}
			  }
		}
		.mv-txt02 {
            width: 59.5vw;
            position: absolute;
            top: 50vw;
            left: 0;
            color: #fff;
			padding: 5vw 5.7vw;
			font-size: 4.2vw;
			line-height: 1.5;
			transition: .5s;
			opacity: 0;
			@media screen and (min-width:769px){
				color: #292f38;
				top: 22.6vw;
				width: 33vw;
				padding: 1.8vw;
				padding-left: 0;
				font-size: 1.54vw;
			}
		}
		.mv-lower {
            width: 85vw;
            position: absolute;
			bottom: -30vw;
            right: 0;
            background: rgba(77,86,100,0.8);
            color: #fff;
			padding: 8.8vw 6.4vw;
			transition: .8s;
			opacity: 0;
			@media screen and (min-width:769px){
				background: #4d5664;
				width: 100%;
				height: 19vw;
				padding: 1vw;
				padding-left: 6.6vw;
				bottom: 4vw;
				display: flex;
				align-items: center;
			}
			.anm {
				@media screen and (min-width:769px){
					display: flex;
					align-items: center;
				}
			}
			.mv-txt03 {
				@include fontMincho;
				font-size: 4.2vw;
				margin-bottom: 20px;
				@media screen and (min-width:769px){
					margin-bottom: 0;
					font-size: 1.43vw;
					height: 7.8vw;
				}
				p {
					margin-bottom: 20px;
					@media screen and (min-width:769px){
						margin-bottom: 10px;
					}
				}
			}
			.bnr {
                display: flex;
                flex-wrap: wrap;
				@media screen and (min-width:769px){
					margin-bottom: 3vw;
				}
				li {
                    width: calc(100% / 2 - 2px);
                    margin-right: 4px;
                    margin-bottom: 4px;
					@media screen and (min-width:769px){
						width: 20vw;
						margin-right: 24px;
						margin-bottom: 0;
					}
                    &:nth-child(2n) {
                        margin-right: 0;
						@media screen and (min-width:769px){
							margin-right: 24px;
						}
                    }
					a {
						img {
						}
					}
				}
			}
			.mv-txt04 {
				font-size: 4vw;
				@media screen and (min-width:769px){
					font-size: 1.3vw;
					height: 7.8vw;
					display: flex;
					align-items: flex-end;
					margin-left: 70px;
				}
				a {
                    color: #fff;
					svg {
						margin-left: 15px;
						.parts01 {
							stroke: #fff;
						}
					}
				}
			}
		}
	}
}

/*============================
#私たちの得意なこと
============================*/
#tokui {
    margin-bottom: 70px;
	@media screen and (min-width:769px){
    }
	.box-inner {
		.mds-txt {
		}
		.tokui-list {
			@media screen and (min-width:769px){
				display: flex;
				flex-wrap: wrap;
			}
			li {
                margin-bottom: 32px;
				@media screen and (min-width:769px){
					margin-right: 32px;
					width: calc(100% / 3 - 22px);
					&:last-child {
						margin-right: 0;
					}
				}
				a {
					.img-box {
                        margin-bottom: 19px;
						img {
						}
					}
					.txt-box {
						.mds {
                            font-weight: bold;
                            font-size: 20px;
                            margin-bottom: 10px;
						}
						.txt {
						}
					}
				}
			}
		}
	}
}

/*============================
#メッセージ
============================*/
#message {
	margin-bottom: 80px;
	.box-inner {
		.pc-flex {
			@media screen and (min-width:769px){
				align-items: flex-start;
			}
			.txt-box {
				margin-right: 30px;
				//width: 48%;
			}
			.mds-txt {
				@media screen and (min-width:769px){
					margin-bottom: 16px;
                }
			}
            .movie-box {}
			.movie-l {
                margin-bottom: 25px;
				position: relative;
				width: 100%;
				padding-top: 56.25%;
                @media screen and (min-width:769px){
					width: 50%;
					padding-top: 27.95%;
                }
				iframe {
					position: absolute;
					top: 0;
					right: 0;
					width: 100% !important;
					height: 100% !important;
				}
			}
			.movie-caption {
				&.sp-view {
					margin-bottom: 30px;
				}
			}
			.movie-s {
                margin-bottom: 10px;
				position: relative;
				width: 100%;
				padding-top: 56.25%;
				@media screen and (min-width:769px){
					width: calc(100% / 2 - 15px);
					margin-bottom: 0;
					margin-right: 30px;
					padding-top: 27.35%;
					&:nth-child(2n) {
						margin-right: 0;
					}
                }
				iframe {
					position: absolute;
					top: 0;
					right: 0;
					width: 100% !important;
					height: 100% !important;
				}
			}
		}
	}
}

/*============================
#お知らせ
============================*/
#information {
	margin-bottom: 80px;
	background: #f7f9fc;
	.box-inner {
		padding-bottom: 40px;
		.mds-txt {
			margin-bottom: 28px;
		}
		.info-list {
			li {
				@media screen and (min-width:769px){
					margin-bottom: 10px;
				}
				a {
					.date {
					}
					.ttl {
					}
				}
			}
		}
		.btn-more {
			a {
				.icon-arrow01 {
				}
			}
		}
	}
}

/*============================
#サービス内容
============================*/
#service {
	background: #4d5664;
	margin-bottom: 80px;
	@media screen and (min-width:769px){
		margin-right: 11vw;
	}
	.box-inner {
		margin: 0;
		@media screen and (min-width:769px){
			padding-right: 0;
			padding-left: 10.5vw;
		}
		.mds-txt {
			color: #fff;
			margin-left: 24px;
		}
		.service-list-wrapper {
			.service-box-l {
				.mds {
					.icon-arrow01 {
					}
				}
				.detail {
				}
				.text-list {
					li {
						a {
							.icon-arrow01 {
							}
						}
					}
				}
			}
			.service-box {
                .service-box-s {
					.mds {
						.icon-arrow01 {
						}
					}
					.detail {
					}
				}
				.box02 {
					.mds {
						.icon-arrow01 {
						}
					}
					.detail {
					}
				}
				.box03 {
					.mds {
						.icon-arrow01 {
						}
					}
					.detail {
					}
				}
				.box04 {
					.mds {
						.icon-arrow01 {
						}
					}
					.detail {
					}
				}
				.box05 {
					.mds {
						.icon-arrow01 {
						}
					}
					.detail {
					}
				}
			}
		}
	}
}

/*============================
#未来へつながる取り組み
============================*/
#mirai {
	margin-bottom: 80px;
	&:before {
		content: "";
		position: absolute;
		right: 0;
		top: -7%;
		width: 74%;
		height: 50%;
		background-color: #F7F9FC;
		z-index: -1;
		@media screen and (min-width:769px){
			top: -14%;
			width: 84%;
			height: 119%;
		}
	  }
	.box-inner {
		.mds-txt {
		}
		.catch-txt {
			@media screen and (min-width:769px){
				margin-bottom: 25px;
			  }
		}
		.mirai-box {
			opacity: 0;
			transition: .3s;
			width: calc(100% + 24px);
			@media screen and (min-width:769px){
				width: 100%;
			  }
		}
		.article-list {
			li {
				a {
					.img-box {
						img {
						}
					}
					.txt-box {
						.mds {
						}
						.date {
						}
						.tag {
							span {
							}
						}
					}
				}
			}
		}
		.btn-more {
			@media screen and (min-width:769px){
				text-align: right;
				padding-right: 30px;
			}
			a {
				.icon-arrow01 {
				}
			}
		}
	}
}

/*============================
#スタッフ ブログ
============================*/
#blog {
	margin-bottom: 80px;
	.box-inner {
		.mds-txt {
		}
		.catch-txt {
			margin-bottom: 20px;
		}
		.article-list {
			li {
				a {
					.img-box {
						img {
						}
					}
					.txt-box {
						.mds {
						}
						.date {
						}
						.tag {
							span {
							}
						}
					}
				}
			}
		}
		.btn-more {
			a {
				.icon-arrow01 {
				}
			}
		}
	}
}

/*============================
#instagram
============================*/
#instagram {
	background: #f7f9fc;
	padding-bottom: 20px;
	margin-bottom: 80px;
	.box-inner {
		.mds-txt {
		}
		.insta-wrapper {
			.insta-list {
				display: flex;
				flex-wrap: wrap;
				margin-bottom: 20px;
				.insta-item {
					width: calc(100% / 3);
					@media screen and (min-width:769px){
						width: calc(100% / 5 - 12px);
						margin-right: 12px;
						margin-bottom: 12px;
						&:last-child {
							display: none;
						}
					  }
					a {
						img {
						}
					}
				}
			}
		}
		.btn-more {
			@media screen and (min-width:769px){
				text-align: right;
				margin-right: 50px;
			}
			a {
				i.icon-arrow01 {
				}
			}
		}
	}
}

/*============================
#contact
============================*/
#contact {
	.contact-box {
		@media screen and (min-width:769px){
			margin-bottom: 120px;
		}
		.box-inner {
			@media screen and (min-width:769px){
			  padding: 0;
			}
		  }
		.box-l {
			.mds-txt {
			}
			.txt01 {
			}
			.txt02 {
			}
			.txt03 {
			}
		}
		.box-r {
			.btn02 {
				a {
				}
			}
		}
	}
}


.bnr-expo {
	margin: -30px 24px 40px;
	border: 3px solid #858C98;
	padding: 12px 16px 18px;
	max-width: 760px;
	@media screen and (min-width:769px) {
		display: flex;
		align-items: center;
		gap: 11px;
		padding: 9px;
		margin: -30px auto 40px;
	}
	.logo {
		text-align: center;
		margin-bottom: 5px;
		@media screen and (min-width:769px) {
			margin-bottom: 0;
		}
		img {
			@media screen and (min-width:769px){
				width: 220px;
			}
		}
		figcaption {
			font-size: 10px;
			@media screen and (min-width:769px){
				font-size: 12px;
			}
		}
	}
	.txt {
		font-size: 12px;
		text-align: center;
		@media screen and (min-width:769px) {
			font-size: 20px;
			text-align: left;
		}
	}
}